import _, { get } from 'lodash';
import '../style/schedule.scss';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc, setDoc, doc, getDoc } from "firebase/firestore";
import { getWeek } from '../js/util';

const firebaseConfig = {
  apiKey: process.env.FirebaseAPIKEY,
  authDomain: process.env.FirebaseAuthDomain,
  databaseURL: process.env.FirebaseDatabaseURL,
  projectId: process.env.FirebaseProjectId,
  storageBucket: process.env.FirebaseStorageBucket,
  messagingSenderId: process.env.FirebaseMessagingSenderId,
  appId: process.env.FirebaseAppId,
  measurementId: process.env.FireabaseMeasurementId,
  credentials: process.env.FirebaseAdmin
}

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const weekOfYear = getWeek(new Date());
console.log('weekOfYear', weekOfYear);

window.onload = (event) => {
  console.log("schedule page is fully loaded");
  initializePage();
};

async function initializePage() {
  const targetContainer = document.getElementById('studioSchedule');
  const todayDt = new Date();
  let uniqueWeekNo = [];
  let sourceStr;
  let paddedStr;

  const maxLimit = 13;
  for (let i = 0; i <= maxLimit; i++) {
    let tmpDt = getWeek(new Date(new Date().setDate(todayDt.getDate() + i)));
    uniqueWeekNo.includes(tmpDt) ? null : uniqueWeekNo.push(tmpDt);
  }

  let twoWeeksSchedule = {};

  for (let week of uniqueWeekNo) {
    let docPath = `/schedule/2024/week/${week}`;
    const docRef = doc(db, docPath);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let responseSchedule = docSnap.data().schedule;
      Object.assign(twoWeeksSchedule, responseSchedule)
    } else {
      // console.log("No such document!");
    }
  }

  console.log(twoWeeksSchedule);
  // Preparing to append dataCard
  let counter = 1;

  for (let i = 0; i <= maxLimit; i++) {
    let ref_date = new Date(todayDt);
    ref_date.setDate(ref_date.getDate() + i);
    let iDt = ref_date.getDate();
    let tmpDay = ref_date.toLocaleDateString(undefined, { weekday: 'short' });
    let frag__skeleton;

    let lv_scheme;
    let frag__skeleton__child;
    //PROCESS DATA
    if (twoWeeksSchedule.hasOwnProperty(iDt)) {
      frag__skeleton__child = '';
      for (let interator of twoWeeksSchedule[iDt]) {

        (counter % 2) == 0 ? lv_scheme = 2 : lv_scheme = 1;
        sourceStr = `${counter}`;
        paddedStr = sourceStr.padStart(2, '0');

        let timeStr = new Date(interator.time.seconds * 1000).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        });

        let tmp_date = new Date(interator.time.seconds * 1000);
        let timeStr2 = new Date(interator.time.seconds * 1000).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
        let timeStr3 = timeStr2.replace(':', '');
        let instructor = interator.instructor;
        instructor = instructor.toUpperCase();
        let datepadded = `${tmp_date.getDate()}`;
        datepadded = datepadded.padStart(2, '0');

        let concatClassId = `${tmp_date.getFullYear()}${tmp_date.getMonth() + 1}${datepadded}${instructor}${timeStr3}`;

        // handle event attachment
        // console.log(interator.booking);
        // console.log(interator.ftb);

        let lv_booking = interator.booking !== undefined ? interator.booking : false;
        let lv_ftb = interator.ftb !== undefined ? interator.ftb : false;
        frag__skeleton__child += `
            <div class="blockContainer">
              <div class="scheduleBlock scheme${lv_scheme} card_rounded" data-classtype="${interator.class}${interator.duration}"
                data-instructor="${interator.instructor}" data-classid="${concatClassId}" data-time="${interator.time.seconds * 1000}" data-ftb="${lv_ftb}" data-booking="${lv_booking}" id="${tmp_date.getFullYear()}${tmp_date.getMonth() + 1}${tmp_date.getDate()}_${paddedStr}">
                <span class="span3">${timeStr}</span>
                <span class="span1">${interator.instructor}</span>
                <span class="span3">${interator.class}</span>
                <span class="span3">${interator.duration} minutes</span>
              </div>
            </div>`;

        counter++;
      }
    } else {

    }
    frag__skeleton = `
          <div class="colCard">
            <div class="dataCard_header card_rounded">
              <p class="head text_centered" id="cHeader${paddedStr}">${tmpDay}</p>
              <p class="middle text_centered" id="cMiddle${paddedStr}">${iDt}</p>
            </div>`;
    frag__skeleton__child == undefined ? null : frag__skeleton += frag__skeleton__child;
    frag__skeleton += `</div >`;

    let indiDiv = document.createRange().createContextualFragment(frag__skeleton);
    targetContainer.appendChild(indiDiv);


  }


  //Set event listeners
  let scheduleDOM = document.getElementsByClassName('scheduleBlock');

  for (let el of scheduleDOM) {
    if (el.dataset.booking !== undefined) {
      if (el.dataset.booking == "true") {
        el.addEventListener('click', handle_scheduleDOM)
      } else {
        console.log('No booking for ', el.id);
      }
    }
  }
}

function handle_scheduleDOM() {
  console.log(this);
  var newUrl = "https://alphabeatsbn.com/bookings?";
  newUrl += `classid=${this.dataset.classid}&instructor=${this.dataset.instructor}&classtype=${this.dataset.classtype}&time=${this.dataset.time}&ftb=${this.dataset.ftb}`;
  window.location = newUrl;
}