Date.prototype.getMDay = function () {
  return (this.getDay() + 6) % 7;
}

Date.prototype.getISOYear = function () {
  var thu = new Date(this.getFullYear(), this.getMonth(), this.getDate() + 3 - this.getMDay());
  return thu.getFullYear();
}

Date.prototype.getISOWeek = function () {
  var onejan = new Date(this.getISOYear(), 0, 1);
  var wk = Math.ceil((((this - onejan) / 86400000) + onejan.getMDay() + 1) / 7);
  if (onejan.getMDay() > 3) wk--; return wk;
}

function getWeek(date) {
  return date.getISOWeek()
}

export {
  getWeek,
}